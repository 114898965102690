<!--
 * @Author: leo
 * @Description: 添加随迁人员
-->
<template>
    <div v-loading="isLoading" class="product-form">
        <!-- 步骤条 -->
        <el-steps :active="currentStep" align-center finish-status="success">
            <el-step v-for="(item, index) in titleList" :key="index"
                :description="item.title" :style="`cursor: ${currentStep < index ? 'default' : 'pointer'};`"
                @click.native="chooseStep(index)"
            >
                <template slot="title">
                    <el-progress type="circle" :percentage="parseInt(item.percentage)" :status="item.percentage === 100 ? 'success' : null" />
                </template>
            </el-step>
        </el-steps>
        <!-- 按需引入对应组件 -->
        <component :ref="currentItem.component" :is="currentItem.component" :current-item="currentItem"
            :loading.sync="isLoading" @update="getData(currentStep)" @next="getData(currentStep+1);"
        />
        <!-- 操作按钮 -->
        <div class="operations-btn">
            <el-button v-if="currentStep!==0" :loading="isLoading" @click="getData(currentStep-1)">上一步</el-button>
            <el-button v-if="currentStep !==  titleList.length - 1" :loading="isLoading" type="primary" @click="nextStep(currentItem.component, 1)">保存</el-button>
            <el-button :loading="isLoading" type="primary" @click="nextStep(currentItem.component, 2)">{{ currentStep ===  titleList.length - 1 ? '完成并提交资料' : '下一步' }}</el-button>
        </div>
    </div>
</template>

<script>
import User from '../../../components/Form/Transfer/User/User.vue'
import Follower from '../../../components/Form/Transfer/Follower/FollowerTransfer.vue'
import Basics from '../../../components/Form/Transfer/Basics/BasicsTransfer.vue'
import Done from '../../../components/Form/Transfer/Done/DoneTransfer.vue'
import { getClientInfo, getKey, setKey } from '../../../api/gifted'
export default {
    name: 'Transfer',
    components: {
        // 每个组件就是一个步骤
        User, Follower, Basics, Done,
    },
    data() {
        return {
            isLoading: false,
            titleList: [], // 每一步的表单信息
            currentStep: null, // 当前到第几步了
            currentItem: {}, // 当前步骤的数据
        }
    },
    created() {
        this.isLoading = true
        Promise.all([this.getData()]).finally(() => {
            this.isLoading = false
        })
    },
    methods: {
        // 获取数据
        async getData(step = null) {
            if(step && step > this.titleList.length - 1) {
                this.$router.push({
                    name: "GiftedDetails",
                    params: {
                        id: Number(this.$route.params.id),
                    },
                })
                return
            }
            try {
                this.isLoading = true
                const res = await getClientInfo({
                    order_id: Number(this.$route.params.id),
                    step,
                })
                if(res.code === 200) {
                    if(step === this.currentStep + 1 || step === this.currentStep - 1) {
                        this.toTop();
                    }
                    this.currentStep = res.data.current_step
                    this.titleList = res.data.progress_bar
                    this.currentItem = {
                        order_id: Number(this.$route.params.id),
                        component: this.titleList.find((item, index) => index === this.currentStep).component,
                        ...this.titleList.find((item, index) => index === this.currentStep).data,
                    }
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },

        // 选择某一步
        async chooseStep(curSelectIndex) {
            if(curSelectIndex >= this.currentStep) return // 不可以选当前及之后的步骤
            this.getData(curSelectIndex)
        },

        /**
         * @description 保存&下一步
         * @param {name} 组件名称
         * @param {handle} 1保存 2下一步
         */
        nextStep(name, handle) {
            this.$refs[name].checkInfo(handle)
        },
        toTop() {
            document.getElementsByTagName('html')[0].scrollIntoView({
                block: "start",
                behavior: "smooth",
            })
        },
    },
}
</script>

<style lang="less" scoped>

</style>