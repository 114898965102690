<template>
    <div class="mt-20">
        <Container title="配偶 - 随行">
            <div slot="handle">
                <el-popconfirm placement="top" title="确定删除？" @confirm="del" v-if="isShow">
                    <el-button slot="reference" size="mini">删除</el-button>
                </el-popconfirm>
            </div>
            <div v-if="isShow">
                <template>
                    <Container :is-fillet="false" title="配偶 - 国籍" red-font="请优先输入您的国籍，我们将根据您的国籍做证件的匹配">
                        <el-form ref="nationality" :model="ruleForm" label-width="150px">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item
                                        label="国籍/地区："
                                        prop="nationality"
                                        :rules="{ required: true, message: '请输入选择', trigger: 'change' }"
                                    >
                                        <CountrySelect v-model="ruleForm.nationality" style="width: 100%;" placeholder="请输入对应的内容" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="港澳通行证："
                                        prop="is_handled"
                                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                                    >
                                        <el-select v-model="ruleForm.is_handled" placeholder="请选择" clearable style="width: 100%;">
                                            <el-option label="办理" :value="1" />
                                            <el-option label="未办理" :value="0" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </Container>
                    <!-- 证件上传 -->
                    <Container
                        :is-fillet="false"
                        v-if="ruleForm.nationality && ruleForm.is_handled !== ''"
                        title="配偶 - 证件"
                        red-font="可通过电脑上传证件，可为您节省该页40%的信息填写时间"  
                    >
                        <CertificateUpload ref="CertificateUpload"
                            :nationality="ruleForm.nationality" :is-handled="ruleForm.is_handled"
                            :certificates="ruleForm.certificates"  @handleInfo="getCertificate"
                        />
                    </Container>
                </template>
                <Container :is-fillet="false" title="配偶 - 个人信息">
                    <SpouseInfo ref="spouseInfo" :data.sync="ruleForm"></SpouseInfo>
                </Container>
                <template>
                    <Container :is-fillet="false" title="配偶 - 中国身份证" red-font="可上传图片识别">
                            <CnidForm ref="CnidForm"
                                :card-info="ruleForm.certificates.cn_identity_card"
                                :required="ruleForm.nationality && ruleForm.nationality.includes('中国-')"
                            />
                    </Container>
                    <Container :is-fillet="false" v-if="ruleForm.is_handled === 1" title="配偶 - 港澳通行证" red-font="可上传图片识别">
                        <HkmPass ref="HkmPass" :card-info="ruleForm.certificates.hk_macao_pass" :required="ruleForm.is_handled === 1" />
                    </Container>
                    <Container :is-fillet="false" title="配偶 - 护照" red-font="暂不支持上传图片识别">
                        <Passport ref="Passport"
                            :card-info="ruleForm.certificates.passport"
                            :required="ruleForm.nationality && !ruleForm.nationality.includes('中国-')"
                        />
                    </Container>
                </template>
            </div>
        </Container>
        <div class="add-box">
            <div v-if="!isShow" class="add-btn" @click="addChildren">
                <el-button type="text">+新增配偶</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import CnidForm from '../../../../../components/CnidForm/CnidForm.vue'
import HkmPass from '../../../../../components/HkmPass/HkmPass.vue';
import Passport from '../../../../../components/Passport/Passport.vue';
import SpouseInfo from '@/components/Form/Gifted/Family/C/SpouseInfo.vue'
export default {
    props: {
        married: {
            type: String || Number,
            default: () => ''
        },
        data: {
            type: Object,
            default: () => null
        }
    },
    components: {
        SpouseInfo,
        CnidForm,
        HkmPass,
        Passport
    },
    data() {
        return {
            isShow: false,
            ruleForm: {
                // relation: 3,
                // nationality: '',
                // subset_name: '',
                // used_name: '',
                // birthday: '',
                // birth_place_aboard: 0,
                // birth_place: {
                //     area: [],
                //     country: '',
                //     details: '',
                //     foreign: ''
                // },
                // occupation: '',
                // occupation_name: '',
                // child_has_hk_id: {
                //     has: ''
                // },
                // hk_identity_card: '',
                // // 随行
                // has_degree: '',
                // has_degree_json: [
                //     {
                //         degree: '',
                //         address: ''
                //     }
                // ],
                // certificates: {
                //     cn_identity_card: {
                //         img_back: '',
                //         img_front: '',
                //         issue_at: '',
                //         issue_date_end_at: '',
                //         issue_date_start_at: '',
                //         number: ''
                //     },
                //     hk_macao_pass: {
                //         img_back: '',
                //         img_front: '',
                //         issue_at: '',
                //         issue_date_end_at: '',
                //         issue_date_start_at: '',
                //         number: ''
                //     },
                //     passport: {
                //         img_back: '',
                //         img_front: '',
                //         issue_at: '',
                //         issue_date_end_at: '',
                //         issue_date_start_at: '',
                //         number: ''
                //     }
                // },
                // nows: '',
                // is_handled: '',
                // address: {
                //     area: [],
                //     country: '',
                //     details: '',
                //     foreign: ''
                // },
                // child_in_hk: {
                //     has: '',
                //     info: ''
                // },
                // electronic_visa_date: '',
                // hk_identity: ''
            },
        }
    },
    watch: {
        'ruleForm.nationality'(newVal) {
            console.log('newVal',newVal);
            if (this.isShow) {
                if(newVal && newVal.includes('中国-')) {
                    this.$refs.Passport.clearCheck()
                }else {
                    this.$refs.CnidForm.clearCheck()
                }
            }
        },
    },
    created() {
        if (this.data) {
            this.isShow = true
            for(let key in this.data) {
                if (this.data[key] == 100) {
                    this.data[key] = ''
                }
            }
            this.ruleForm = this.data
        }
        // if (this.married && this.married == 3) {
        //     this.ruleForm.follow = 2
        // }
    },
    methods: {
        // 校验
        checkParm() {
            const ruleFormArray = []
            // ruleFormArray.push(this.$refs.ruleForm.validate())
            if(this.isShow) ruleFormArray.push(this.$refs.spouseInfo.checkParm())
            if(this.isShow) {
                ruleFormArray.push(this.$refs.nationality.validate())
                ruleFormArray.push(this.$refs.CnidForm.checkInfo())
                if (this.ruleForm.is_handled === 1) {
                    ruleFormArray.push(this.$refs.HkmPass.checkInfo())
                }
                ruleFormArray.push(this.$refs.Passport.checkInfo())
            }
            console.log('ruleFormArray',ruleFormArray);
            const isCheck = Promise.all(ruleFormArray)
            return isCheck
        },
        getCertificate(info) {
            // 图片
            this.ruleForm.certificates[info.type][`img_${info.id_card_side}`] = info.url
            // 判断证件类型
            switch (info.type) {
                case 'cn_identity_card':
                    if(info.id_card_side === 'front') {
                        this.ruleForm.certificates[info.type].number = info.number
                        if(!this.ruleForm.nationality.includes('中国-')) return
                        // 外籍时 身份证信息不填入主申人信息
                        this.ruleForm.subset_name = info.username
                        this.ruleForm.birthday = info.birthday
                    }
                    if(info.id_card_side === 'back') {
                        this.ruleForm.certificates[info.type].issue_at = info.issue_at
                        this.ruleForm.certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.ruleForm.certificates[info.type].issue_date_end_at = info.issue_date_end_at
                    }
                    break;
                case 'hk_macao_pass':
                    if(info.id_card_side === 'front') {
                        this.ruleForm.certificates[info.type].number = info.number
                        this.ruleForm.certificates[info.type].issue_at = info.issue_at
                        this.ruleForm.certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.ruleForm.certificates[info.type].issue_date_end_at = info.issue_date_end_at
                    }
                    break;
                case 'passport':
                    if(info.id_card_side === 'front') {
                        this.ruleForm.certificates[info.type].number = info.number
                        this.ruleForm.certificates[info.type].issue_at = info.issue_at
                        this.ruleForm.certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.ruleForm.certificates[info.type].issue_date_end_at = info.issue_date_end_at
                        if(this.ruleForm.nationality.includes('中国-')) return
                        // 外籍时 护照信息填入主申人信息
                        this.ruleForm.subset_name = info.username
                        this.ruleForm.birthday = info.birthday
                        // this.ruleForm.sex = info.sex
                    }
                    break;
                default:
                    break;
            }
        },
        // 点击展开配偶表单
        addChildren () {
            console.log('addChildren', this.ruleForm);
            if (this.isShow) return this.$message({message: '仅支持新增1个配偶',type: 'warning'})
            this.isShow = true
            // 组件表单嵌套过多，不好resetFields
            this.ruleForm = {
                relation: 3,
                follow: 1,
                nationality: '',
                subset_name: '',
                used_name: '',
                birthday: '',
                birth_place_aboard: 0,
                birth_place: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                },
                occupation: '',
                occupation_name: '',
                child_has_hk_id: {
                    has: ''
                },
                hk_identity_card: '',
                // 随行
                has_degree: '',
                has_degree_json: [
                    {
                        degree: '',
                        address: ''
                    }
                ],
                certificates: {
                    cn_identity_card: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    hk_macao_pass: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    passport: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    }
                },
                nows: '',
                is_handled: '',
                address: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                },
                child_in_hk: {
                    has: '',
                    info: ''
                },
                electronic_visa_date: '',
                hk_identity: ''
            }
        },
        // 点击隐藏配偶表单
        del () {
            this.isShow = false
            this.ruleForm = null
        }
    },
}
</script>

<style lang="less" scoped>
.mt-20 {
    margin-top: 20px !important;
}
.add-box {
    background: white;
    padding: 20px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-top: 0 none;
    .add-btn {
        border: 1px dashed #ddd;
        padding: 3px 0;
        cursor: pointer;
    }
}
/deep/ .card .lower-left{
    padding: 0 !important;
    border: none;
}
</style>
