<!--
 * @Author: Jacob.Zhang
 * @Description: 港澳通行证
-->
<template>
    <el-form ref="ruleForm" :model="cardInfo" :rules="rules" label-width="140px" class="demo-ruleForm">
        <el-row :gutter="10">
            <el-col :span="12">
                <el-form-item
                    label="港澳通行证："
                    prop="number"
                    :rules="[{ required, trigger: 'blur', message: '请输入港澳通行证' },{ pattern: /^[a-zA-Z0-9]{5,21}$/, message: '请输入正确的港澳通行证' }]"
                >
                    <el-input v-model.trim="cardInfo.number" clearable maxlength="9" placeholder="港澳通行证" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="签发地：" prop="issue_at" :rules="required ? rules.issue_at : [{required: false}]">
                    <el-input v-model="cardInfo.issue_at" clearable maxlength="50" placeholder="请输入签发地" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="签发日期：" prop="issue_date_start_at" :rules="required ? rules.issue_date_start_at : [{required: false}]">
                    <el-date-picker v-model="cardInfo.issue_date_start_at" value-format="yyyy-MM-dd" type="date" placeholder="请选择" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="届满日期：" prop="issue_date_end_at" :rules="required ? rules.issue_date_end_at : [{required: false}]">
                    <el-date-picker v-model="cardInfo.issue_date_end_at" value-format="yyyy-MM-dd" type="date" placeholder="请选择" />
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
export default {
    name: 'HkmPass',
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        cardInfo: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            rules: {
                // number: [{ required: true, trigger: "blur", validator: this.$rules.validate.hkmPass, }],
                issue_at: [{ required: true, message: "请输入签发地", trigger: "blur", }],
                issue_date_start_at: [{ required: true, message: "请选择签发日期", trigger: "change", }],
                issue_date_end_at: [{ required: true, message: "请选择届满日期", trigger: "change", }],
            },
        }
    },
    methods: {
        // 校验表单
        checkInfo() {
            return new Promise(async(resolve, reject) => {
                try {
                    await this.$refs.ruleForm.validate()
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">

</style>
