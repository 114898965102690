<!--
 * @Author: Autumn.again
 * @Date: 2023-07-19 09:41:38
 * @LastEditors: Autumn.again
 * @LastEditTime: 2023-08-08 13:42:37
 * @FilePath: \User-Information-System\src\components\Passport\Passport.vue
 * Copyright: 2023 by Autumn.again, All Rights Reserved.
-->
<!--
 * @Author: Jacob.Zhang
 * @Description: 护照
-->
<template>
    <el-form ref="ruleForm" :model="cardInfo" :rules="rules" label-width="140px" class="demo-ruleForm">
        <el-row :gutter="10">
            <el-col :span="12">
                <el-form-item
                    label="护照及其他旅行证件："
                    label-width="165px"
                    prop="number"
                    :rules="required ? rules.passport_type: [{required: false}]">
                    <el-select v-model="cardInfo.passport_type" placeholder="请选择" clearable>
                        <el-option  v-for="item in certificateList" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item
                    label="证件号码："
                    prop="number"
                    :rules="[{ required, trigger: 'blur', message: '请输入证件号码' },{ pattern: /^([a-zA-z]|[0-9]){5,17}$/, message: '请输入正确的证件号码' }]"
                >
                    <el-input v-model.trim="cardInfo.number" clearable maxlength="9" placeholder="证件号码" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="签发地：" prop="issue_at" :rules="required ? rules.issue_at : [{required: false}]">
                    <el-input v-model="cardInfo.issue_at" clearable maxlength="50" placeholder="请输入签发地" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="签发日期：" prop="issue_date_start_at" :rules="required ? rules.issue_date_start_at : [{required: false}]">
                    <el-date-picker v-model="cardInfo.issue_date_start_at" value-format="yyyy-MM-dd" type="date" placeholder="请选择" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="届满日期：" prop="issue_date_end_at" :rules="required ? rules.issue_date_end_at : [{required: false}]">
                    <el-date-picker v-model="cardInfo.issue_date_end_at" value-format="yyyy-MM-dd" type="date" placeholder="请选择" />
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
export default {
    name: 'Passport',
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        cardInfo: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            rules: {
                // number: [{ required: true, trigger: "change", validator: this.$rules.validate.passport, }],
                certificate: [{ required: true, message: "请选择护照及其他旅行证件", trigger: "change", }],
                issue_at: [{ required: true, message: "请输入签发地", trigger: "blur", }],
                issue_date_start_at: [{ required: true, message: "请选择签发日期", trigger: "change", }],
                issue_date_end_at: [{ required: true, message: "请选择届满日期", trigger: "change", }],
            },
            upLoadDialog: false,
            certificateList: [
                {value: 3, label:'护照'},
                {value: 4, label:'外交护照'},
                {value: 5, label:'公务护照'},
                {value: 6, label:'中国发无国籍旅游证件'},
                {value: 7, label:'旅游通行证'},
                {value: 8, label:'联合国通行证'},
                {value: 9, label:'其他无国籍旅行证件'},
                {value: 10, label:'其他'},
            ],
        }
    },
    created(){
        if(this.cardInfo.passport_type === 11){
            // 此类型为默认未选择，需要特殊处理
            this.cardInfo.passport_type = ''
        }
    },
    methods: {
        // 校验表单
        checkInfo() {
            return new Promise(async(resolve, reject) => {
                try {
                    await this.$refs.ruleForm.validate()
                    if(this.cardInfo.passport_type === ''){
                        // 此类型为默认未选择，需要特殊处理
                        this.cardInfo.passport_type = undefined
                    }
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        },
        // 清空校验表单
        clearCheck() {
            this.$refs.ruleForm.clearValidate()
        },
    }
}
</script>

<style scoped lang="less">

</style>
