<!--
 * @Author: Jacob.Zhang
 * @Description: 中国身份证
-->
<template>
    <el-form ref="ruleForm" :model="cardInfo" :rules="rules" label-width="140px" class="demo-ruleForm">
        <el-row :gutter="10">
            <el-col :span="12">
                <el-form-item label="中国身份证号码：" prop="number"
                    :rules="required ? [{ required: true, trigger: 'blur', message: '请输入身份证号码' },{ pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号码' }] :
                    [{ required: false, pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号码', trigger: 'blur' }]"
                >
                    <el-input v-model.trim="cardInfo.number" clearable maxlength="18" placeholder="请输入中国身份证号码" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="签发地：" prop="issue_at"
                :rules="required ? [{ required: true, message: '请输入签发地', trigger: 'blur' },{ pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入中文' }] :
                [{ required: false, pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入中文', trigger: 'blur' }]"
                >
                    <el-input v-model.trim="cardInfo.issue_at" clearable maxlength="50" placeholder="请输入签发地" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="签发日期：" prop="issue_date_start_at" :rules="required ? rules.issue_date_start_at : [{required: false}]">
                    <el-date-picker v-model="cardInfo.issue_date_start_at" value-format="yyyy-MM-dd" type="date" placeholder="请选择" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="届满日期：" prop="issue_date_end_at" :rules="required ? rules.issue_date_end_at : [{required: false}]" class="expiry-date">
                    <el-date-picker v-if="!longTerm" v-model="cardInfo.issue_date_end_at" value-format="yyyy-MM-dd" type="date" placeholder="请选择" />
                    <el-checkbox v-model="longTerm" @change="triggerDate">长期</el-checkbox>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
export default {
    name: 'CnidForm',
    props: {
        required: {
            type: Boolean,
            default: true,
        },
        cardInfo: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    watch: {
        // OCR识别后监听届满日期
        'cardInfo.issue_date_end_at': {
            handler(newVal) {
                if(newVal === '8888-01-01') {
                    this.longTerm = true
                    this.triggerDate(true)
                } else {
                    this.longTerm = false
                }
            },
            deep: true,
            immediate: true,
        },
    },
    data() {
        return {
            rules: {
                // number: [{ required: this.required, trigger: 'blur', pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入身份证号码' }],
                // issue_at: [
                //     { required: true, message: "请输入签发地", trigger: "blur" },
                //     { pattern: /^[\u4e00-\u9fa5]+$/, message: "请输入中文" }
                // ],
                issue_date_start_at: [{ required: true, message: "请选择签发日期", trigger: "change", }],
                issue_date_end_at: [{ required: true, message: "请选择届满日期", trigger: "change", }],
            },
            longTerm: false,
        }
    },
    methods: {
        // 校验表单
        checkInfo() {
            return new Promise(async(resolve, reject) => {
                try {
                    await this.$refs.ruleForm.validate()
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        },
        // 清空校验表单
        clearCheck() {
            this.$refs.ruleForm.clearValidate()
        },
        // 勾选长期与否 改变日期的值
        triggerDate(val) {
            this.cardInfo.issue_date_end_at = val ? '8888-01-01' : ''
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .el-form-item.expiry-date {
    .el-form-item__content {
        display: flex;
        .el-date-editor {
            margin-right: 15px;
        }
        .el-checkbox {
            margin-right: 10px;
        }
    }
}
</style>