import { render, staticRenderFns } from "./ChildrenTransfer.vue?vue&type=template&id=6a78cc29&scoped=true&"
import script from "./ChildrenTransfer.vue?vue&type=script&lang=javascript&"
export * from "./ChildrenTransfer.vue?vue&type=script&lang=javascript&"
import style0 from "./ChildrenTransfer.vue?vue&type=style&index=0&id=6a78cc29&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a78cc29",
  null
  
)

export default component.exports