<!--
 * @Author: leo
 * @Description: 完成
-->
<template>
    <div>
        <div class="done-box">
            <p class="done-tit">资料已全部完成填写</p>
            <p class="score-des">已提交的信息可通过：【个人中心—个人信息表】查看</p>
        </div>
        <!-- 保存按钮 -->
        <!-- <div class="operations-group">
            <el-button class="person-btn" @click="goPre">上一步</el-button>
            <el-button class="person-btn btn-blue" @click="done">确认提交</el-button>
        </div> -->
    </div>
</template>

<script lang="javascript">
import { postClientInfo } from '../../../../api/gifted'
export default {
    name: "Done",
    components: {},
    data() {
        return {
        };
    },
    props: {
        currentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    created() {
        console.log(this.currentItem)
    },
    methods: {
        // 完成并提交
        checkInfo() {
            this.$confirm("提交后您将无法编辑，请再次确认", "确认提交您的个人信息？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                customClass: 'confirm'
            }).then(() => {
                this.currentItem.next = 0;
                this.$emit('update:loading', true)
                postClientInfo(this.currentItem).then((res) => {
                    if (res.code === 200) {
                        // 切换步骤保存 下一步的时候才设置
                        this.$message.success("提交成功！");
                        // this.$emit('next')
                        this.$router.push({
                            name: "JumpPage",
                            params: {
                                order_id: window.location.href.split("/").pop(),
                            },
                        })
                    }
                }).finally(() => {
                    this.$emit('update:loading', false)
                });
            })
        }
    },
};
</script>

<style lang="less" scoped>
// .done-box {
//     width: 960px;
//     margin: 108px auto 0;
//     font-size: 14px;
//     color: #303133;

//     > p {
//         margin-bottom: 16px;
//     }
// }

// .total-score {
//     width: 100%;
//     text-align: center;
//     span.score {
//         display: inline-block;
//         color: #f56c6c;
//         font-size: 24px;
//         font-weight: bold;
//         margin-right: 3px;
//     }
// }

// .score-des {
//     text-align: center;
//     color: #606266;
//     margin-bottom: 24px;
// }

// .categories {
//     font-weight: bold;
//     font-size: 16px;
// }

// .done-tips {
//     margin-top: 16px;
//     color: #606266;
// }
.done-box {
    width: 960px;
    margin: 108px auto 0;
    font-size: 14px;
    color: #303133;
    height: 300px;

    > p {
        margin-bottom: 30px;
    }
    .done-tit {
        text-align: center;
        font-size: 28px;
    }
}

.score-des {
    text-align: center;
    color: #606266;
    margin-bottom: 24px;
}

.operations-group {
    justify-content: center;
}
.operations-group {
    width: 960px;
    margin: 24px auto 50px;
    display: flex;
    justify-content: flex-end;

    .person-btn {
        line-height: 22px;
        font-size: 14px;
        color: #606266;
        padding: 5px 16px;
        border-radius: 2px;
        margin-left: 16px;
        border: 1px solid #DCDFE6;
    }

    .btn-blue {
        color: #ffffff;
        background: #409eff;
        border: none;
    }
}

.operations-group {
    width: 960px;
    margin: 24px auto 50px;
    display: flex;
    justify-content: flex-end;
}

</style>

<style lang="less">
.confirm{
    // 解决遮罩层会盖住确认框的偶现问题
    z-index: 3000!important;
}
</style>