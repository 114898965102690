<!--
 * @Author: leo
 * @Description: 随迁人员及家庭成员信息
-->
<template>
    <div class="family">
        <!-- 配偶信息 - 根据主申请人的婚姻状态配置 ‘配偶信息’ 的显示隐藏 -->
        <Spouse ref="spouse" v-if="currentItem.information.married != 2 && currentItem.information.married != 3" :data="currentItem.spouse" :married="currentItem.information.married" />
        <!-- 子女 -->
        <Children ref="children" :data="currentItem.child"></Children>
        <!-- 父母 -->
        <ParentalInfo ref="parentalInfo" :data-father="currentItem.father" :data-mother="currentItem.mother"></ParentalInfo>
    </div>
</template>

<script>
import Spouse from './C/SpouseTransfer.vue'
import Children from './C/ChildrenTransfer.vue'
import ParentalInfo from '@/components/Form/Gifted/Family/C/ParentalInfo.vue'
import { postClientInfo } from '../../../../api/gifted'
export default {
    props: {
        currentItem: {
            type: Object,
            default:() => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    components: {
        Spouse,
        Children,
        ParentalInfo,
    },
    data() {
        return {
            parentalInfo: []
        }
    },
    created() {
        console.log('currentItem',this.currentItem)
    },
    methods: {
        // 保存和下一步
        async saveProgressBar(type) {
            try {
                this.$emit('update:loading', true)
                const { code } = await postClientInfo(this.currentItem)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    if (type === 0) {
                        this.$emit('update')
                    } else {
                        this.$emit('next')
                    }
                }
            } finally {
                this.$emit('update:loading', false)
            }
        },
        /**
         * @description 校验当前步骤的表单
         * @param {type} 1保存 2下一步
         */
        checkInfo(type) {
            // 根据主申请人的婚姻状态配置 ‘配偶信息’ 的表单校验
            if (this.currentItem.information.married != 2 && this.currentItem.information.married != 3) {
                this.currentItem.spouse = this.$refs.spouse.ruleForm
            } else {
                this.currentItem.spouse = null
            }

            this.currentItem.child = this.$refs.children.ruleForm
            this.currentItem.father = this.$refs.parentalInfo.ruleForm[0]
            this.currentItem.mother = this.$refs.parentalInfo.ruleForm[1]
            if(type === 1) {
                // 保存时不做校验
                // ajax请求
                this.currentItem.next = type - 1
                this.saveProgressBar(this.currentItem.next)
                return
            }
            // 点击的是下一步 校验成功后发送请求
            Promise.all([
                this.currentItem.information.married != 2 && this.currentItem.information.married != 3 && this.$refs.spouse.checkParm(),
                this.$refs.children.checkParm(),
                this.$refs.parentalInfo.checkParm(),
            ])
            .then(async () => {
                this.currentItem.next = type - 1
                this.saveProgressBar(this.currentItem.next)
                // 请求成功后 跳转
                // this.$emit('next')
            })
            .catch((err) => {
                console.log(err)
                setTimeout(() => {
                    this.$utils.scrollIntoError(this)
                }, 100)
            })
        },
    },
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item {
    margin-left: 0;
}
/deep/ .el-radio {
    margin-right: 15px;
}
</style>
