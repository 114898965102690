<!--
 * @Author: leo
 * @Description: 随迁人员-基本资格
-->
<template>
    <div>
        <!-- 配偶 -->
        <div v-if="Object.keys(currentItem.spouse).length > 0">
            <Spouse ref="Spouse" :userForm="currentItem.spouse" style="margin-bottom: 20px"/>
        </div>
        <!-- 子女 -->
        <div v-if="currentItem.child.length > 0">
            <Children ref="Children" :userForm="currentItem" style="margin-bottom: 20px"/>
        </div>
    </div>
</template>

<script lang="javascript">
import Spouse from "./C/SpouseTransfer.vue";
import Children from "./C/ChildrenTransfer.vue";
import { postClientInfo } from '../../../../api/gifted'
export default {
    name: "Basics",
    components: {
        Spouse,
        Children,
    },
    props: {
        currentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        };
    },
    created() {
        console.log(this.currentItem)
    },
    methods: {
        // 保存或下一步
        checkInfo(type) {
            if (type === 1) {
                return this.hanledSaveQuestion(type);
            } else {
                const SpouseFun =  Object.keys(this.currentItem.spouse).length > 0 ? this.$refs['Spouse'].submitForm() : null;
                const ChildrenFun = this.currentItem.child.length > 0 ? this.$refs['Children'].submitForm() : null;
                Promise.all([SpouseFun, ChildrenFun]).then(() => {
                    //全部验证通过就会走这里
                    console.log(this.currentItem)
                    this.hanledSaveQuestion(type)
                }, err => {
                    this.$utils.scrollIntoError(this);
                })
            }
        },
        hanledSaveQuestion(parmas) {
            this.currentItem.next = parmas - 1;
            this.$emit('update:loading', true)
            postClientInfo(this.currentItem).then((res) => {
                if (res.code === 200) {
                    // 切换步骤保存 下一步的时候才设置
                    if (parmas === 2) {
                        this.$message.success("提交成功！");
                        this.$emit('next')
                    } else {
                        this.$message.success("保存成功！");
                        this.$emit('update')
                    }
                }
            }).finally(() => {
                this.$emit('update:loading', false)
            });
        },
    },
};
</script>

<style lang="less" scoped>
.card-heard {
    margin-bottom: 25px;
}

.basics-box {
    padding: 0 24px;
    color: #606266;
    font-size: 14px;

    .basics-title {
        margin-bottom: 9px;
        position: relative;
        padding-left: 12px;

        &::before {
            content: "*";
            position: absolute;
            left: 0;
            top: 3px;
            color: red;
        }
    }

    .basics-title.no-tip {
        padding-left: 0;

        &::before {
            content: "";
        }
    }
}

.same-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 47px;

    .el-radio {
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }
    }

    .input-line {
        width: 782px;
    }

    /deep/ .el-form-item__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .el-radio-group {
        width: 140px;
    }
}
</style>
